import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {graphql} from "gatsby";
import { useSwipeable } from 'react-swipeable'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";

import Layout from '../components/layout'
import { MapView, PaginationFooter } from "../components/sections";
import { Geocoder, WineryFilters } from "../components/composites";
import { ListItem } from "../components/primitives";
import { getFilteredWineries, setExperienceArray } from "../utils";
import { useMobileBool } from "../utils/hooks";

import './map.scss'


const MapPage = ({data, location}) => {
    const {
        directus: {
            wineries,
        }
    } = data;
    const { search } = location;

    const listRef = useRef(null);
    const mapRef = React.useRef(null);
    const isMobile = useMobileBool()
    const [loading, setLoading] = useState(!mapRef.current)
    const filterPreppedArray = useMemo(() => setExperienceArray(wineries.filter(({google_rating}) => google_rating > 0).sort((a, b) => (a.google_rating > b.google_rating) ? -1 : 1 || b.google_total_reviews - a.google_total_reviews)), [wineries])
    const [hideList, setHideList] = useState(false)
    const [wineriesInViewport, setWineriesInViewport] = useState([])

    const params = new URLSearchParams(location.search)
    const routeLat = params.get('lat')
    const routeLng = params.get('lng')
    const routeZoom = params.get('zoom')
    const wineryName = params.get('winery')
    const pageNumber = Number(params.get("page") ?? 1);

    const isWinery = wineryName ?? false;
    const title = `${isWinery ? wineryName : 'Find A Winery'} | Explore Wineries Worldwide`;
    const description = isWinery ? `Curious where ${wineryName} is located? Find wineries across the world using our map.` : 'Use our map to explore wineries all around the world!';
    const startWineryDisplay = (pageNumber - 1) * 10;
    const endWineryDisplay = pageNumber * 10;

    const [wineriesToDisplay, setWineriesToDisplay] = useState(wineriesInViewport.slice(startWineryDisplay, endWineryDisplay))

    const [viewport, setViewport] = useState({
        width: '100%',
        height: '100%',
        latitude: routeLat ?? 41.5074,
        longitude: routeLng ?? -0.1278,
        zoom: routeZoom ?? 4,
        transitionDuration: 1000,
    });

    const setWineriesList = () => {
        const filteredResults = getFilteredWineries(location, filterPreppedArray)
        const viewPortWineries = filterWineriesInView(filteredResults)
        setWineriesInViewport(viewPortWineries)
        setWineriesToDisplay(viewPortWineries.slice(startWineryDisplay, endWineryDisplay))
        isMobile && setHideList(true)
    }

    useLayoutEffect( () => {
        setWineriesList()
    }, [search])

    const handleLoad = () => {
        setLoading(true)
        setWineriesList()
    }

    useEffect(() => {
        mapRef?.current?.flyTo({center: [routeLng, routeLat], duration: 2000, zoom: routeZoom})
    }, [routeLat, routeLng, routeZoom])

    const onMapMove = (event) => {
        setViewport(event.viewState)
        isMobile && setHideList(true)
        setWineriesList()
    }

    const zoomIn = () => {
        setViewport({
            ...viewport,
            zoom: viewport.zoom + 1,
            transitionDuration: 500,
        });
        setWineriesList()
    };

    const zoomOut = () => {
        setViewport({
            ...viewport,
            zoom: viewport.zoom - 1,
            transitionDuration: 500,
        });
        setWineriesList()
    };

    function filterWineriesInView(wineries) {
        if(!mapRef.current) return wineries;
        const mapBounds = mapRef?.current?.getBounds();
        const bounds = {neLat: mapBounds._ne.lat, neLong: mapBounds._ne.lng, swLat: mapBounds._sw.lat, swLong: mapBounds._sw.lng}
        return wineries.filter( ({id, latitude, longitude}) => inBounds(Number(latitude), Number(longitude), bounds))
    }

    function inBounds(pointLat, pointLong, bounds) {
        const neLong = bounds.neLong;
        const neLat = bounds.neLat;
        const swLong = bounds.swLong;
        const swLat = bounds.swLat;

        const eastBound = pointLong < neLong;
        const westBound = pointLong > swLong;
        let inLong;

        if (neLong < swLong) {
            inLong = eastBound || westBound;
        } else {
            inLong = eastBound && westBound;
        }

        let inLat = pointLat > swLat && pointLat < neLat;
        return inLat && inLong;
    }

    const handleMapToggle = () => {
        setHideList(!hideList)
        !isMobile && setTimeout(() => {
            mapRef.current.resize();
            setWineriesList()
        }, 600)
    }


    const handlers = useSwipeable({
        onSwipedUp: () => isMobile && setHideList(false),
        onSwipedDown: () => isMobile && setHideList(true),
        preventScrollOnSwipe: true
    });

    return(
        <Layout
            className='map-page'
            currentPage='about'
            title={title}
            location={location}
            description={description}
            hideFooter
        >
           <div className={`list-container ${hideList ? 'hide' : ''}`}>
               <div className='swipe-container'>
                   <div className='line-container' {...handlers} ><hr className='small-line'/></div>
                   <p className='wineries-in-search'>{wineriesInViewport.length} Wineries Found</p>
                   <WineryFilters hiddenFilters={['Country', 'Grape']} location={location}/>
               </div>
               <div className='winery-container'>
                   <ul className='list-wineries' ref={listRef}>
                       {wineriesToDisplay.map( (winery, index) => (
                           <ListItem
                               key={`list-item-${index}`}
                               {...winery}
                           />
                       ))}
                   </ul>
                   <PaginationFooter setDataToDisplay={setWineriesToDisplay} data={wineriesInViewport} itemsCountPerPage={10} scrollToRef={listRef} pageNumber={pageNumber} location={location}/>
               </div>
           </div>
           <div className={`map-container ${hideList ? 'full' : ''}`}>
               <button className='toggle-list-view mapbox-btn' onClick={() => handleMapToggle()}>
                   <FontAwesomeIcon icon={faAngleLeft} className={`${hideList ? 'rotate-angle' : ''}`}  />
               </button>
               {isMobile && <Geocoder className='on-map-search' id='on-map-search' />}
                <MapView
                    wineries={wineriesInViewport}
                    hideList={hideList}
                    mapWidth={`${hideList ? '100vw' : '100%'}`}
                    mapRef={mapRef}
                    onMapMove={onMapMove}
                    viewport={viewport}
                    setViewport={setViewport}
                    zoomIn={zoomIn}
                    zoomOut={zoomOut}
                    handleLoad={handleLoad}
                />
           </div>
        </Layout>
    )
}

MapPage.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
}

MapPage.defaultProps = {
}

export const MapPageQuery = graphql`
  query {
    directus {
      wineries(
       limit: -1
       sort: ["-vind_score"]
       filter: {status: {_eq: "published"}, google_total_reviews: {_nnull: true}}
      ) {
          additional_images {
            directus_files_id {
              filename_disk
              }
         }
        slug
        country {
          name
          slug
        }
        google_total_reviews
        google_rating
        latitude
        longitude
        name
        region
        slug
        instagram
        summary
        featured_image {
            filename_disk
         }
         accommodation
         cellar_door
         events
         restaurant
         tours
         tastings
        tags {
         tags_id {
          name
         }
        }
      }
    }
  }
`

export default MapPage